import React from 'react';

function HireMe() {
  return (
    <div id="hire-me-content">
      <h1>Hire Me</h1>
      <p>
        I'm an L4 engineer at a FAANG. I was promoted in 10 months.
      </p>
      <p>
        Are you looking to fill a product or "anything and everything" role at a high growth startup?
      </p>
      <p>
        Let's chat—email me at <a>ycombinations@proton.me</a>.
      </p>
    </div>
  );
}

export default HireMe;
