import React, { useState, useEffect } from 'react';

function CompanyToolTip({ company, children }) {
  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, text: '' });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the screen width is less than 768px (mobile view)
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    checkIsMobile();

    // Add event listener to handle window resize
    window.addEventListener('resize', checkIsMobile);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  const handleClick = (event) => {
    if (isMobile) return; // Do nothing if it's mobile

    event.stopPropagation(); // Prevent clicks from propagating up
    setTooltip((prevTooltip) => ({
      visible: !prevTooltip.visible, // Toggle visibility
      x: event.clientX,
      y: event.clientY,
      text: `${company.company_x} x ${company.company_y}`,
    }));
  };

  const handleMouseMove = () => {
    if (isMobile) return; // Do nothing if it's mobile

    // Hide the tooltip on any mouse move
    setTooltip((prevTooltip) => ({ ...prevTooltip, visible: false }));
  };

  useEffect(() => {
    if (!isMobile && tooltip.visible) {
      // Add mousemove event listener when tooltip is visible and not on mobile
      window.addEventListener('mousemove', handleMouseMove);
    } else {
      // Remove mousemove event listener when tooltip is not visible or on mobile
      window.removeEventListener('mousemove', handleMouseMove);
    }

    // Cleanup event listeners on component unmount or when visibility changes
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [tooltip.visible, isMobile]); // Re-run if tooltip visibility or mobile state changes

  return (
    <>
      {/* The content that triggers the tooltip */}
      <div
        onClick={handleClick}
        style={{ display: 'inline-block', width: '100%', cursor: 'pointer' }}
      >
        {children}
      </div>

      {/* Tooltip logic, positioned absolutely with respect to the viewport */}
      {!isMobile && tooltip.visible && (
        <div
          className="tooltip"
          style={{
            position: 'fixed',
            top: tooltip.y + 5,
            left: tooltip.x + 10,
            backgroundColor: 'rgba(198, 198, 198, 0.8)',
            padding: '5px',
            fontSize: '14px',
            color: '#fff',
            borderRadius: '3px',
            pointerEvents: 'none',
            zIndex: 1000,
            whiteSpace: 'nowrap',
          }}
        >
          {tooltip.text}
        </div>
      )}
    </>
  );
}

export default CompanyToolTip;

