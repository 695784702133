import React from 'react';

function Main({
  sortOption,
  handleSortChange,
  isTopCompaniesChecked,
  handleCheckboxChange,
  checkedBatches,
  isIndustryOpen,
  toggleIndustry,
  checkedIndustries,
  checkedRegions,
  toggleRegion,
  isRegionOpen,
  isTagsOpen,
  toggleTags,
  checkedTags,
  immediateSearchQuery,
  handleSearchChange,
  selectedPills,
  handlePillRemove,
  companies,
  lastCompanyElementRef,
  getS3LogoUrl,
  yc_logo,
  CompanyToolTip,
  CompanyRating,
  BatchSelector,
  IndustryCheckbox,
  RegionsCheckbox,
  TagsCheckbox,
  PlusIconButton,
  MinusIconButton,
  setSearchQuery,
  setImmediateSearchQuery,
  isSidebarOpen,
  setIsSidebarOpen,
  switchToTrending,
  setPage,
  setSortBy,
  loading
}) {

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
    setSortBy("default");  // Set the sort option to "default"
    setPage(1);  // Reset the page to 1
  };

  return (
    <div>
      <div id="hero">
        <h1>Startup Directory</h1>
        <p>Every Y Combinator company with an "X for Y" description scraped and recombined into all possible X for Y combinations.</p>
        <p>A 3.5 million company-long, exhaustive list of every terrible "Uber for Pets" (it's in there) pitch you've heard.</p>
        <p>You can search for Y Combinations by batch, industry, region, and more. Upvote your favorites and check out the <a href="#" id="trending-link" onClick={switchToTrending}>trending</a> list.</p>
        <p>(Yes, these are permutations.)</p>
      </div>
      <div id="sort-by">
        <label htmlFor="sort">Sort by </label>
        <select id="sort" value={sortOption} onChange={handleSortChange}>
          <option value="default">Default</option>
          <option value="trending">Trending</option>
          <option value="controversial">👎</option>
        </select>
      </div>
      <div className="main-content">
        <div id="sidebar" className={isSidebarOpen ? 'open' : ''}>
          <div id="top-companies-container" className="sidebar-container">
            <label>
              <input
                type="checkbox"
                id="top-companies-checkbox"
                checked={isTopCompaniesChecked}
                onChange={() => handleCheckboxChange("Top Companies")}
              />
              💎 Top Companies
            </label>
          </div>
          <BatchSelector onCheckboxChange={handleCheckboxChange} checkedBatches={checkedBatches}/>
          <div id="industry-container" className="sidebar-container">
            <h4>Industry 
              {isIndustryOpen ? <MinusIconButton onClick={toggleIndustry} /> : <PlusIconButton onClick={toggleIndustry} />}
            </h4>
            {isIndustryOpen && (
              <IndustryCheckbox 
                onCheckboxChange={handleCheckboxChange} 
                checkedIndustries={checkedIndustries} // Pass down the checked state for industries
              />
            )}
          </div>
          <div id="region-container" className="sidebar-container">
            <h4>Region 
              {isRegionOpen ? <MinusIconButton onClick={toggleRegion} /> : <PlusIconButton onClick={toggleRegion} />}
            </h4>
            {isRegionOpen && (
              <RegionsCheckbox 
                onCheckboxChange={handleCheckboxChange} 
                checkedRegions={checkedRegions}
              />
            )}
          </div>
          <div id="tags-container" className="sidebar-container">
            <h4>Tags 
              {isTagsOpen ? <MinusIconButton onClick={toggleTags} /> : <PlusIconButton onClick={toggleTags} />}
            </h4>
            {isTagsOpen && (
              <TagsCheckbox  
                onCheckboxChange={handleCheckboxChange}
                checkedTags={checkedTags}
              />
            )}
          </div>
          <div className="mobile-only-button-container">
            <button className="mobile-show-companies-button" onClick={toggleSidebar}>
                Show 1,000,000+ companies
            </button>
          </div>
        </div>
        <div className="right-content">
          <div id="search-bar">
            <input
              type="text"
              placeholder="Search..."
              value={immediateSearchQuery}
              onChange={handleSearchChange}
            />
            <div className="search-pill-container">
              {immediateSearchQuery && (
                <div className="search-pill">
                  <span>{immediateSearchQuery}</span>
                  <button onClick={() => {
                    setSearchQuery(''); // Clear the search query
                    setImmediateSearchQuery(''); // Immediately clear the input and pill
                    setPage(1);
                  }}>
                    &times;
                  </button>
                </div>
              )}
              {selectedPills.map((pill, index) => {
                let processedPill = pill.toLowerCase() === "america-canada" ? "America / Canada" : pill;

                const displayLabel = processedPill.toLowerCase() === "e-commerce"
                  ? processedPill
                  : processedPill.includes('-') 
                    ? processedPill.split('-').pop() 
                    : processedPill;
                
                const finalDisplayLabel = displayLabel.toLowerCase() === "b2b" ? "B2B" : displayLabel.charAt(0).toUpperCase() + displayLabel.slice(1);

                return (
                  <div className="search-pill" key={index}>
                    <span>{finalDisplayLabel}</span>
                    <button onClick={() => handlePillRemove(finalDisplayLabel)}>&times;</button>
                  </div>
                );
              })}
            </div>
          </div>
          {/* Overlay for darkening the background */}
          {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}
          <div id="mobile-filter-button-content">
            <button className="mobile-filter-button" onClick={toggleSidebar}>Filter</button>
          </div>
          <div id="showing-text">
            Showing {companies.length} of 1,000,000+ companies
          </div>
          <div id="companies">
            {loading ? (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            ) : (
              <ul>
                {companies.map((company, index) => {
                  if (companies.length === index + 1) {
                  return (
                    <li ref={lastCompanyElementRef} key={index} className="company-item">
                      <CompanyToolTip company={company}>
                        <div className="company-container">
                          <div className="company-details">
                            <div className="logo-container">
                              <img 
                                src={getS3LogoUrl(company.concatenatedTags)} 
                                alt={`${company.name} logo`} 
                                className="company-logo" 
                              />
                            </div>
                            <div className="info-container">
                              <div className="header">
                                <p id="name">{company.name}</p>
                                <p id="location">{company.location}</p>
                              </div>
                              <div className="description">
                                <p id="description">{company.x_description} for {company.y_description}</p>
                              </div>
                              <div className="tags">
                                {company.concatenatedTags.map((tag, index) => (
                                  <span key={index} className="tag-pill">
                                    {index === 0 && (
                                      <img src={yc_logo} alt="yc_logo" className="tag-logo" />
                                    )}
                                    {tag}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <CompanyRating company={company} />
                        </div>
                      </CompanyToolTip>
                    </li>
                  );
                } else {
                  return (
                    <li key={index} className="company-item">
                      <CompanyToolTip company={company}>
                        <div className="company-container">
                          <div className="company-details">
                            <div className="logo-container">
                              <img 
                                src={getS3LogoUrl(company.concatenatedTags)} 
                                alt={`${company.name} logo`} 
                                className="company-logo" 
                              />
                            </div>
                            <div className="info-container">
                              <div className="header">
                                <p id="name">{company.name}</p>
                                <p id="location">{company.location}</p>
                              </div>
                              <div className="description">
                                <p id="description">{company.x_description} for {company.y_description}</p>
                              </div>
                              <div className="tags">
                                {company.concatenatedTags.map((tag, index) => (
                                  <span key={index} className="tag-pill">
                                    {index === 0 && (
                                      <img src={yc_logo} alt="yc_logo" className="tag-logo" />
                                    )}
                                    {tag}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <CompanyRating company={company} />
                        </div>
                      </CompanyToolTip>
                    </li>
                  );
                }
              })}
            </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
