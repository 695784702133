import React, { useState, useEffect } from 'react';

const RegionsCheckbox = ({ onCheckboxChange = () => {}, checkedRegions = {} }) => {
  const [dropdowns, setDropdowns] = useState({});
  const [showAll, setShowAll] = useState(false);

  const regions = [
    {
      name: 'America / Canada', id: 'america-canada', dropdown: [
        'United States of America',
        'Canada'
      ]
    },
    {
      name: 'Remote', id: 'remote', dropdown: [
        'Partly Remote',
        'Fully Remote'
      ]
    },
    {
      name: 'Europe', id: 'europe', dropdown: [
        'United Kingdom', 'France', 'Germany', 'Spain', 'Denmark', 'Switzerland',
        'Netherlands', 'Ireland', 'Sweden', 'Austria', 'Poland', 'Norway', 'Slovenia',
        'Italy', 'Portugal', 'Estonia', 'Finland', 'Greece', 'Romania', 'Ukraine',
        'Belarus', 'Belgium', 'Croatia', 'Cyprus', 'Czechia', 'Georgia', 'Hungary',
        'Iceland', 'Latvia', 'Lithuania', 'Russia', 'Slovakia'
      ]
    },
    {
      name: 'South Asia', id: 'south-asia', dropdown: [
        'India', 'Pakistan', 'Bangladesh', 'Nepal'
      ]
    },
    {
      name: 'Latin America', id: 'latin-america', dropdown: [
        'Mexico', 'Brazil', 'Colombia', 'Chile', 'Argentina', 'Peru', 'Panama', 'Puerto Rico',
        'Uruguay', 'Venezuela', 'Costa Rica', 'Ecuador'
      ]
    },
    {
      name: 'Southeast Asia', id: 'southeast-asia', dropdown: [
        'Singapore', 'Indonesia', 'Philippines', 'Vietnam', 'Malaysia', 'Thailand'
      ]
    },
    {
      name: 'Africa', id: 'africa', dropdown: [
        'Nigeria', 'Kenya', 'Ghana', 'Senegal', 'Ethiopia', 'South Africa', 'Tanzania',
        'Uganda', 'Benin', 'Democratic Republic of the Congo', 'Ivory Coast', 'Namibia',
        'Rwanda', 'Seychelles', 'Zambia'
      ]
    },
    {
      name: 'Middle East and North Africa', id: 'mena', dropdown: [
        'Israel', 'Egypt', 'United Arab Emirates', 'Saudi Arabia', 'Turkey', 'Morocco', 'Algeria',
        'Armenia', 'Bahrain', 'Iraq'
      ]
    },
    {
      name: 'East Asia', id: 'east-asia', dropdown: [
        'China', 'Hong Kong', 'South Korea', 'Japan'
      ]
    },
    {
      name: 'Oceania', id: 'oceania', dropdown: [
        'Australia', 'New Zealand'
      ]
    },
  ];

  const toggleDropdown = (regionId) => {
    setDropdowns(prevState => ({
      ...prevState,
      [regionId]: !prevState[regionId]
    }));
  };

  const handleParentCheckboxChange = (e, regionId) => {
    onCheckboxChange(regionId);
  };

  const handleNestedCheckboxChange = (regionId, nestedId) => {
    const fullLabel = `${regionId}-${nestedId}`;
    onCheckboxChange(fullLabel);
  };

  const toggleShowAll = (e) => {
    e.preventDefault();
    setShowAll(!showAll);
  };

  return (
    <div className="regions-container">
      {regions.slice(0, showAll ? regions.length : 5).map(region => (
        <div className="region-checkbox" key={region.id}>
          <div className="checkbox-label-container">
            <input
              type="checkbox"
              id={region.id}
              onChange={(e) => handleParentCheckboxChange(e, region.id)}
              checked={checkedRegions[region.id] || false}
            />
            {region.dropdown && (
              <span
                className={`dropdown-icon ${dropdowns[region.id] ? 'open' : ''}`}
                onClick={() => toggleDropdown(region.id)}
              >
                &#9662; {/* Unicode for a downwards triangle */}
              </span>
            )}
            <label htmlFor={region.id}>{region.name}</label>
          </div>
          {region.dropdown && (
            <div className="dropdown" id={`${region.id}-dropdown`} style={{ display: dropdowns[region.id] ? 'block' : 'none' }}>
              {region.dropdown.map((nested, index) => (
                <div key={index}>
                  <input
                    type="checkbox"
                    id={`${region.id}-${nested}`}
                    className="nested"
                    checked={checkedRegions[`${region.id}-${nested}`] || false}
                    onChange={() => handleNestedCheckboxChange(region.id, nested)}
                  />
                  <label htmlFor={`${region.id}-${nested}`}>{nested}</label>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <a href="#" onClick={toggleShowAll} className="toggle-options-link">
        {showAll ? 'See fewer options' : 'See all options'}
      </a>
    </div>
  );
};

export default RegionsCheckbox;


