import React, { useState } from 'react';

function BatchSelector({ onCheckboxChange = () => {}, checkedBatches = {} }) {
  const [showAllBatches, setShowAllBatches] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleBatches = () => {
    setShowAllBatches((prevState) => !prevState);
  };

  const toggleCollapse = () => {
    setIsCollapsed(prev => !prev);
  };

  const initialBatches = [
    "S24",
    "W24",
    "S23",
    "W23",
    "S22",
    "W22",
    "S21",
  ];

  const additionalBatches = [
    "S20",
    "W20",
    "S19",
    "W19",
    "S18",
    "W18",
    "S17",
    "W17",
    "S16",
    "W16",
    "S15",
    "W15",
    "S14",
    "W14",
    "S13",
    "W13",
    "S12",
    "W12",
    "S11",
    "W11",
    "S10",
    "W10",
    "S09",
    "W09",
    "S08",
    "W08",
    "S07",
    "W07",
    "S06",
    "W06",
    "S05",
    "W05",
  ];

  const displayedBatches = showAllBatches
    ? [...initialBatches, ...additionalBatches]
    : initialBatches;

  const handleBatchCheckboxChange = (batch) => {
    onCheckboxChange(batch); // Call the passed down function with the batch label
  };

  return (
    <div id="batch-container" className="sidebar-container">
      <h4>
        Batch
        {isCollapsed ? <PlusIconButton onClick={toggleCollapse} /> : <MinusIconButton onClick={toggleCollapse} />}
      </h4>
      {!isCollapsed && (
        <>
          <ul className="batch-list">
            {displayedBatches.map((batch, index) => (
              <li key={index}>
                <label>
                  <input
                    type="checkbox"
                    checked={checkedBatches[batch] || false} // Use the checkedBatches prop
                    onChange={() => handleBatchCheckboxChange(batch)} // Handle batch checkbox change
                  />
                  {batch}
                </label>
              </li>
            ))}
          </ul>
          <a href="#toggle" onClick={handleToggleBatches}>
            {showAllBatches ? "See fewer options" : "See all options"}
          </a>
        </>
      )}
    </div>
  );
}

// Updated SVG Components for icons
const MinusIconButton = ({ onClick }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <rect x="0.5" y="0.5" width="14" height="14" fill="#D8D8D8" rx="2" ry="2" />
    <rect x="3" y="7" width="9" height="1" fill="white" rx="1" ry="1" />
  </svg>
);

const PlusIconButton = ({ onClick }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <rect x="0.5" y="0.5" width="14" height="14" fill="#D8D8D8" rx="2" ry="2" />
    <rect x="3" y="7" width="9" height="1" fill="white" rx="1" ry="1" />
    <rect x="7" y="3" width="1" height="9" fill="white" rx="1" ry="1" />
  </svg>
);

export default BatchSelector;

