// src/supabaseClient.js
import { useState } from 'react';
import { createClient } from '@supabase/supabase-js';

// Supabase URL and Public API Key

const supabaseUrl = 'https://glvewqqzsnwktzlqpakm.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdsdmV3cXF6c253a3R6bHFwYWttIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzc5Njc1NzEsImV4cCI6MTk5MzU0MzU3MX0.HUSLmWpJGE58oYZ6mEXxXIID1Jtp8cM4dP_PltGn3hI';

// Create a single supabase client instance
export const supabase = createClient(supabaseUrl, supabaseAnonKey);
// Create a single supabase client instance
export const fetchCompanies = async (
  setCompanies,
  page,
  sortBy = 'default',
  searchTerm = '',
  selectedPills = [],
  checkedBatches = {},
  isTopCompaniesChecked = false,
  checkedIndustries = {},
  checkedRegions = {},
  checkedTags = {}) => {

  console.log(`Fetching companies for page: ${page}, sort by: ${sortBy}, search term: ${searchTerm}`);
  console.log('Selected batches: ', checkedBatches);
  console.log('Selected industries: ', checkedIndustries);
  console.log('Selected Regions: ', checkedRegions);
  console.log('Selected Tags: ', checkedTags);

  const limit = 20;
  const totalRows = 3500000; // Total number of rows in the table
  const randomThreshold = Math.floor(Math.random() * (totalRows - limit)); 
  
  // Start with selecting from the view
  let query = supabase
    .from('combinations_with_companies')
    .select('*');
  
  // Sorting
  if (sortBy === 'trending') {
    // When sorting by trending, only select where rating > 0
    query = query
      .gt('combination_rating', 0)
      .order('combination_rating', { ascending: false })
      .range((page - 1) * limit, (page * limit) - 1);
  } else if (sortBy === 'controversial') {
    // When sorting by controversial, only select where rating < 0
    query = query
      .lt('combination_rating', 0)
      .order('combination_rating', { ascending: true })
      .range((page - 1) * limit, (page * limit) - 1);
  } else {
    // Otherwise, use the random order
    query = query
      .gt('random_order', randomThreshold)
      .order('random_order', { ascending: true })
      .range((page - 1) * limit, (page * limit) - 1);
    console.log('Random offset', randomThreshold);
  }
  
  // Search term filtering
  if (searchTerm) {
    query = query.textSearch('search_vector', searchTerm, {
      config: 'english',
      type: 'plain'
    });
  }
  
  // Batch filtering
  const selectedBatches = Object.keys(checkedBatches).filter(batch => checkedBatches[batch]);
  if (selectedBatches.length > 0 && !selectedBatches.includes('All batches')) {
    query = query
      .in('batch_x', selectedBatches)
      .in('batch_y', selectedBatches);
  }
  
  // Top Companies filtering
  if (isTopCompaniesChecked) {
    query = query
      .eq('top_company_x', true)
      .eq('top_company_y', true);
  }
  
// Combine selected industries and tags into a single array
const selectedFilters = [
  ...Object.keys(checkedIndustries).filter(industry => checkedIndustries[industry]),
  ...Object.keys(checkedTags).filter(tag => checkedTags[tag])
];

// If there are any selected filters, build the query
if (selectedFilters.length > 0) {
  const combinedSearchString = selectedFilters.join(' '); // Use `|` for OR in full-text search
  
  query.textSearch('search_vector', combinedSearchString, {
    config: 'english',
    type: 'plain'
  });
}
  
  // Region filtering with mapping
  const continentToCountriesMap = {
    "america-canada": ["USA", "Canada"],
    "latin-america": [
      "Brazil", "Mexico", "Colombia", "Chile", "Argentina", "Peru", 
      "Panama", "Puerto Rico", "Uruguay", "Costa Rica", "Venezuela", "Ecuador"
    ],
    "europe": [
      "United Kingdom", "Germany", "France", "Spain", "Italy", "Denmark",
      "Switzerland", "Netherlands", "Ireland", "Sweden", "Austria", "Poland",
      "Norway", "Slovenia", "Portugal", "Estonia", "Finland", "Greece",
      "Romania", "Ukraine", "Belarus", "Belgium", "Croatia", "Cyprus", 
      "Czechia", "Georgia", "Hungary", "Iceland", "Latvia", "Lithuania",
      "Russia", "Slovakia"
    ],
    "east-asia": ["China", "Hong Kong", "Japan", "South Korea"],
    "africa": [
      "Nigeria", "South Africa", "Kenya", "Egypt", "Morocco", "Ghana",
      "Senegal", "Tanzania", "Uganda", "Benin", "Democratic Republic of the Congo",
      "Ivory Coast", "Namibia", "Rwanda", "Seychelles", "Zambia"
    ],
    "oceania": ["Australia", "New Zealand"],
    "middle-east-and-north-africa": [
      "Israel", "Egypt", "United Arab Emirates", "Saudi Arabia", "Turkey",
      "Morocco", "Algeria", "Armenia", "Bahrain", "Iraq"
    ],
    "remote": ["Partly Remote", "Fully Remote"],
    "southeast-asia": ["Singapore", "Indonesia", "Philippines", "Vietnam", "Malaysia", "Thailand"],
    "south-asia": ["India", "Pakistan", "Bangladesh", "Nepal"],
  };
  
  const selectedRegions = Object.keys(checkedRegions)
  .filter(region => checkedRegions[region])
  .flatMap(region => {
    // Replace "United States of America" with "USA"
    if (region === "United States of America") {
      region = "USA";
    }

    // Map continent to countries
    if (continentToCountriesMap[region]) {
      return continentToCountriesMap[region];
    }

    // Find the last hyphen and remove everything before it
    const lastHyphenIndex = region.lastIndexOf('-');
    if (lastHyphenIndex !== -1) {
      region = region.slice(lastHyphenIndex + 1).trim();
    }

    return [region];
  });

if (selectedRegions.length > 0) {
  const orConditions = selectedRegions.map(region => 
    `location_y.ilike.%${region}%`
  ).join(',');

  query = query.or(orConditions);
}

  const { data, error } = await query;
  
  if (error) {
    console.error('Error fetching companies:', error.message);
    return;
  }
  
  console.log('Fetched companies:', data);
  
  const companiesWithOriginals = data.map(row => {
    const tagsXArray = row.tags_x;
    const tagsYArray = row.tags_y;

    let batch;
    if (row.batch_x === row.batch_y) {
      batch = row.batch_x;
    } else {
      batch = `${row.batch_x}/${row.batch_y}`;
    }

    const concatenatedTags = [
      batch,
      ...new Set([...(tagsXArray || []), ...(tagsYArray || [])])
    ].map(tag => tag ? tag.toUpperCase() : '');

    const company_x = row.company_x || '';
    const company_y = row.company_y || '';
    const location = row.location_y || "";
    const rating = row.combination_rating;
    const name = row.new_name ? row.new_name.trim() : "Company";
    
    return {
      ...row,
      concatenatedTags,
      location,
      company_x,
      company_y,
      name,
      batch,
      rating
    };
  });

  console.log('Processed companies:', companiesWithOriginals);

  setCompanies(prevCompanies => {
    const newCompanies = companiesWithOriginals.filter(newCompany => 
      !prevCompanies.some(existingCompany => existingCompany.name === newCompany.name)
    );
    return [...prevCompanies, ...newCompanies];
  });
};


function CompanyRating({ company }) {
  const [rating, setRating] = useState(company.rating); // Current rating
  const [initialRating] = useState(company.rating); // Store the initial rating from the database
  const [clicked, setClicked] = useState(null); // Track which button was clicked: 'upvote' or 'downvote'

  const updateRating = async (newRating) => {
    const { data, error } = await supabase
      .rpc('update_company_rating', { 
        new_rating: newRating,
        company_id: company.id
      });
    
    if (error) {
      console.error('Failed to update rating');
      console.log('Error:', error);
    } else {
      setRating(newRating); // Update the rating in the UI
    }
  };

  const handleUpvote = () => {
    let newRating;
    if (clicked === 'downvote') {
      // If the user had previously downvoted, reset to initial rating and then upvote
      newRating = initialRating;
      setClicked(null);
    } else if (clicked === null) {
      // First time upvoting, simply increase the rating
      newRating = rating + 1;
      setClicked('upvote');
    }
    updateRating(newRating);
  };

  const handleDownvote = () => {
    let newRating;
    if (clicked === 'upvote') {
      // If the user had previously upvoted, reset to initial rating and then downvote
      newRating = initialRating;
      setClicked(null);
    } else if (clicked === null) {
      // First time downvoting, simply decrease the rating
      newRating = rating - 1;
      setClicked('downvote');
    }
    updateRating(newRating);
  };

  return (
    <div className="arrows-container">
      <div className="upvote">
        <button 
          onClick={handleUpvote} 
          className={clicked === 'upvote' ? 'clicked' : ''}
          disabled={clicked === 'upvote'} // Disable the button if already clicked
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 4l-8 8h16z" />
          </svg>
        </button>
      </div>
      <div className="score">
        <p>{rating}</p>
      </div>
      <div className="downvote">
        <button 
          onClick={handleDownvote} 
          className={clicked === 'downvote' ? 'clicked' : ''}
          disabled={clicked === 'downvote'} // Disable the button if already clicked
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 20l-8-8h16z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export {CompanyRating};