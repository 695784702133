import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://glvewqqzsnwktzlqpakm.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdsdmV3cXF6c253a3R6bHFwYWttIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzc5Njc1NzEsImV4cCI6MTk5MzU0MzU3MX0.HUSLmWpJGE58oYZ6mEXxXIID1Jtp8cM4dP_PltGn3hI';
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const TagsCheckbox = ({ onCheckboxChange = () => {}, checkedTags = {}}) => {
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchTags = async () => {
      const { data, error } = await supabase.from('tags').select('*');
      if (error) {
        console.error('Error fetching tags:', error);
      } else {
        setTags(data);
        setFilteredTags(data); // Initialize with all tags
      }
    };

    fetchTags();
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = tags.filter(tag =>
      tag.tag_name.toLowerCase().includes(query)
    );
    setFilteredTags(filtered);
  };

  const handleCheckboxChange = (tagId) => {
    onCheckboxChange(tagId);
  };

  return (
    <div className="tags-container">
      <input
        type="text"
        placeholder="Search for a tag..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="tags-search-bar"
      />
      <div className="tags-list">
        {filteredTags.map(tag => (
          <div className="tag-checkbox" key={tag.tag_name}>
            <input 
              type="checkbox"
              id={tag.tag_name}
              onChange={(e) => handleCheckboxChange(tag.tag_name)}
              checked={checkedTags[tag.tag_name] || false}
            />
            <label htmlFor={tag.tag_name}>{tag.tag_name}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagsCheckbox;
