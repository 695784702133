import React, { useState } from 'react';

function IndustryCheckbox({ onCheckboxChange = () => {}, checkedIndustries = {} }) {
  const [dropdowns, setDropdowns] = useState({});
  const [showAll, setShowAll] = useState(false);

  const industries = [
    { name: 'B2B', id: 'b2b', dropdown: ['Analytics', 'Engineering, Product and Design', 'Finance and Accounting', 'Human Resources', 'Infrastructure', 'Legal', 'Marketing', 'Office Management', 'Operations', 'Productivity', 'Recruiting and Talent', 'Retail', 'Sales', 'Security', 'Supply Chain and Logistics'] },
    { name: 'Education', id: 'education' },
    { name: 'Fintech', id: 'fintech', dropdown: ['Asset Management', 'Banking and Exchange', 'Consumer Finance', 'Credit and Lending', 'Insurance', 'Payments'] },
    { name: 'Consumer', id: 'consumer', dropdown: ['Apparel and Cosmetics', 'Consumer Electronics', 'Content', 'Food and Beverage', 'Gaming', 'Home and Personal', 'Job and Career Services', 'Social', 'Transportation Services', 'Travel, Leisure and Tourism', 'Virtual and Augmented Reality'] },
    { name: 'Healthcare', id: 'healthcare', dropdown: ['Consumer Health and Wellness', 'Diagnostics', 'Drug Discovery and Delivery', 'Healthcare IT', 'Healthcare Services', 'Industrial Bio', 'Medical Devices', 'Therapeutics'] },
    { name: 'Real Estate and Construction', id: 'real-estate', dropdown: ['Construction', 'Housing and Real Estate'] },
    { name: 'Industrials', id: 'industrials', dropdown: ['Agriculture', 'Automotive', 'Aviation and Space', 'Climate', 'Drones', 'Energy', 'Manufacturing and Robotics'] },
    { name: 'Government', id: 'government' },
    { name: 'Unspecified', id: 'unspecified' }
  ];

  const toggleDropdown = (industryId) => {
    setDropdowns(prevState => ({
      ...prevState,
      [industryId]: !prevState[industryId]
    }));
  };

  const handleParentCheckboxChange = (e, industryId) => {
    onCheckboxChange(industryId);
  };

  const handleNestedCheckboxChange = (industryId, nestedId) => {
    const fullLabel = `${industryId}-${nestedId}`;
    onCheckboxChange(fullLabel);
  };

  const toggleShowAll = (e) => {
    e.preventDefault();
    setShowAll(!showAll);
  };

  return (
    <div className="industries-container">
      {industries.slice(0, showAll ? industries.length : 5).map(industry => (
        <div className="industry-checkbox" key={industry.id}>
          <div className="checkbox-label-container">
            <input
              type="checkbox"
              id={industry.id}
              onChange={(e) => handleParentCheckboxChange(e, industry.id)}
              checked={checkedIndustries[industry.id] || false}
            />
            {industry.dropdown && (
              <span
                className={`dropdown-icon ${dropdowns[industry.id] ? 'open' : ''}`}
                onClick={() => toggleDropdown(industry.id)}
              >
                &#9662;
              </span>
            )}
            <label htmlFor={industry.id}>{industry.name}</label>
          </div>
          {industry.dropdown && (
            <div className="dropdown" id={`${industry.id}-dropdown`} style={{ display: dropdowns[industry.id] ? 'block' : 'none' }}>
              {industry.dropdown.map((nested, index) => (
                <div key={index}>
                  <input
                    type="checkbox"
                    id={`${industry.id}-${nested}`}
                    className="nested"
                    checked={checkedIndustries[`${industry.id}-${nested}`] || false}
                    onChange={() => handleNestedCheckboxChange(industry.id, nested)}
                  />
                  <label htmlFor={`${industry.id}-${nested}`}>{nested}</label>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <a href="#" onClick={toggleShowAll} className="toggle-options-link">
        {showAll ? 'See fewer options' : 'See all options'}
      </a>
    </div>
  );
}

export default IndustryCheckbox;
