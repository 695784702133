import React, { useEffect, useState, useRef, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import logo from './logo.svg';
import yc_logo from './ycombinator_logo.png'
import './App.css';
import { fetchCompanies, CompanyRating } from './supabaseClient'; 
import CompanyToolTip from './companyToolTip';
import useDebounce from './useDebounce';
import BatchSelector from './batchSelector';
import IndustryCheckbox from './industryCheckbox';
import RegionsCheckbox from './regionsCheckbox';
import TagsCheckbox from './tagsCheckbox';
import Main from './Main';
import HireMe from './HireMe';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [companies, setCompanies] = useState([]);
  const [keepLoading, setKeepLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [sortOption, setSortBy] = useState("default");
  const [searchQuery, setSearchQuery] = useState(""); 
  const [selectedPills, setSelectedPills] = useState([]);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [checkedBatches, setCheckedBatches] = useState({});
  const observer = useRef();
  const [immediateSearchQuery, setImmediateSearchQuery] = useState("");
  const [checkedIndustries, setCheckedIndustries] = useState({});
  const [checkedRegions, setCheckedRegions] = useState({});
  const [checkedTags, setCheckedTags] = useState({});
  const [isTopCompaniesChecked, setIsTopCompaniesChecked] = useState(false);

  const [isIndustryOpen, setIndustryOpen] = useState(true);
  const [isRegionOpen, setRegionOpen] = useState(true);
  const [isTagsOpen, setTagsOpen] = useState(true);

  const toggleIndustry = () => setIndustryOpen(!isIndustryOpen);
  const toggleRegion = () => setRegionOpen(!isRegionOpen);
  const toggleTags = () => setTagsOpen(!isTagsOpen);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Function to toggle the menu icon
  const toggleMenuIcon = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const regionsList = [
    'anywhere',
    'america-canada',
    'america-canada-United States of America',
    'america-canada-Canada',
    'remote',
    'remote-Partly Remote',
    'remote-Fully Remote',
    'europe',
    'europe-United Kingdom',
    'europe-France',
    'europe-Germany',
    'europe-Spain',
    'europe-Denmark',
    'europe-Switzerland',
    'europe-Netherlands',
    'europe-Ireland',
    'europe-Sweden',
    'europe-Austria',
    'europe-Poland',
    'europe-Norway',
    'europe-Slovenia',
    'europe-Italy',
    'europe-Portugal',
    'europe-Estonia',
    'europe-Finland',
    'europe-Greece',
    'europe-Romania',
    'europe-Ukraine',
    'europe-Belarus',
    'europe-Belgium',
    'europe-Croatia',
    'europe-Cyprus',
    'europe-Czechia',
    'europe-Georgia',
    'europe-Hungary',
    'europe-Iceland',
    'europe-Latvia',
    'europe-Lithuania',
    'europe-Russia',
    'europe-Slovakia',
    'south-asia',
    'south-asia-India',
    'south-asia-Pakistan',
    'south-asia-Bangladesh',
    'south-asia-Nepal',
    'latin-america-Mexico',
    'latin-america-Brazil',
    'latin-america',
    'latin-america-Colombia',
    'latin-america-Chile',
    'latin-america-Argentina',
    'latin-america-Peru',
    'latin-america-Panama',
    'latin-america-Puerto Rico',
    'latin-america-Uruguay',
    'latin-america-Venezuela',
    'latin-america-Costa Rica',
    'latin-america-Ecuador',
    'southeast-asia',
    'southeast-asia-Singapore',
    'southeast-asia-Indonesia',
    'southeast-asia-Philippines',
    'southeast-asia-Vietnam',
    'southeast-asia-Malaysia',
    'southeast-asia-Thailand',
    'africa',
    'africa-Nigeria',
    'africa-Kenya',
    'africa-Ghana',
    'africa-Senegal',
    'africa-Ethiopia',
    'africa-South Africa',
    'africa-Tanzania',
    'africa-Uganda',
    'africa-Benin',
    'africa-Democratic Republic of the Congo',
    'africa-Ivory Coast',
    'africa-Namibia',
    'africa-Rwanda',
    'africa-Seychelles',
    'africa-Zambia',
    'mena',
    'mena-Israel',
    'mena-Egypt',
    'mena-United Arab Emirates',
    'mena-Saudi Arabia',
    'mena-Turkey',
    'mena-Morocco',
    'mena-Algeria',
    'mena-Armenia',
    'mena-Bahrain',
    'mena-Iraq',
    'east-asia',
    'east-asia-China',
    'east-asia-Hong Kong',
    'east-asia-South Korea',
    'east-asia-Japan',
    'oceania',
    'oceania-Australia',
    'oceania-New Zealand'
  ];
  
  const tagsList = [
    'Fintech',
    'B2B',
    'SaaS',
    'Marketplace',
    'Latin America',
    'E-commerce',
    'Payments',
    'Education',
    'Proptech',
    'Logistics',
    'Crypto / Web3',
    'Neobank',
    'eLearning',
    'Consumer Health Services',
    'Developer Tools',
    'Subscriptions',
    'API',
    'Artificial Intelligence',
    'Machine Learning',
    'Community',
    'Consumer',
    'Delivery',
    'Grocery',
    'Lending',
    'AI',
    'Banking as a Service',
    'Climate',
    'Construction',
    'Food Tech',
    'HR Tech',
    'Health & Wellness',
    'Insurance',
    'Mental Health Tech',
    'Real Estate',
    'Telecommunications',
    'Consumer Finance',
    'Digital Health',
    'Enterprise',
    'Health Tech',
    'Investing',
    'LegalTech',
    'Retail',
    'Transportation',
    'Travel',
    'AI Assistant',
    'Analytics',
    'Auto Commerce',
    'Automation',
    'Biotech',
    'Data Engineering',
    'Data Science',
    'DevSecOps',
    'Diagnostics',
    'Enterprise Software',
    'Hard Tech',
    'Healthcare',
    'IoT',
    'Marketing',
    'Medical Devices',
    'Messaging',
    'Mobility',
    'Open Source',
    'Payroll',
    'Productivity',
    'Sales',
    'Solar Power',
    'Supply Chain',
    'Sustainability',
    'Telemedicine',
    'Advertising',
    'Agriculture',
    'Assistive Tech',
    'Augmented Reality',
    'Autonomous Delivery',
    'Beauty',
    'Cashierless Checkout',
    'Chatbot',
    'Civic Tech',
    'ClimateTech',
    'Collaboration',
    'Commercial Space Launch',
    'Compliance',
    'Computer Vision',
    'Creator Economy',
    'Cultured Meat',
    'Customer Service',
    'Data Visualization',
    'FinOps',
    'Fitness',
    'Fraud Prevention',
    'Fundraising',
    'Geographic Information System',
    'GovTech',
    'Home Services',
    'Identity',
    'Kubernetes',
    'NLP',
    'Neurotechnology',
    'No-code',
    'Primary Care'
  ];

  const PlusIconButton = ({ onClick }) => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <rect x="0.5" y="0.5" width="14" height="14" fill="#D8D8D8" rx="2" ry="2" />
      <rect x="3" y="7" width="9" height="1" fill="white" rx="1" ry="1" />
      <rect x="7" y="3" width="1" height="9" fill="white" rx="1" ry="1" />
    </svg>
  );

  const MinusIconButton = ({ onClick }) => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <rect x="0.5" y="0.5" width="14" height="14" fill="#D8D8D8" rx="2" ry="2" />
      <rect x="3" y="7" width="9" height="1" fill="white" rx="1" ry="1" />
    </svg>
  );

  const handleSortChange = (event) => {
    setLoading(true);
    setSortBy(event.target.value);
    setPage(1);
  };

  const switchToTrending = (event) => {
    setLoading(true);
    event.preventDefault(); // Prevent default link behavior
    setSortBy('trending'); // Set the dropdown to "Trending"
    setPage(1);
  };

  const handleSearchChange = (event) => {
    setLoading(true);
    setSearchQuery(event.target.value);
    setImmediateSearchQuery(event.target.value);
    setSortBy('default');
    setPage(1);
  };

  const handleCheckboxChange = (label) => {
    setLoading(true);
    setSelectedPills((prevSelectedPills) => {
      const newPills = prevSelectedPills.includes(label)
        ? prevSelectedPills.filter(pill => pill !== label)
        : [...prevSelectedPills, label];

      return newPills;
    });

    if (label === "Top Companies") {
      setIsTopCompaniesChecked(!isTopCompaniesChecked);
    } else if (regionsList.includes(label)) {
      setCheckedRegions((prevChecked) => ({
        ...prevChecked,
        [label]: !prevChecked[label]
      }));
    } else if (label.startsWith("S") || label.startsWith("W") || label === "All batches") {
      setCheckedBatches((prevChecked) => ({
        ...prevChecked,
        [label]: !prevChecked[label]
      }));
    } else if (tagsList.includes(label)) {
      setCheckedTags((prevChecked) => ({
        ...prevChecked,
        [label]: !prevChecked[label]
      })); 
    } else {
      setCheckedIndustries((prevChecked) => ({
        ...prevChecked,
        [label]: !prevChecked[label]
      }));
    }
    setSortBy('default');
    setPage(1);
  };

  const handlePillRemove = (displayLabel) => {
    setLoading(true);
    const fullLabel = selectedPills.find(pill => {
      let processedPill = pill.toLowerCase() === "america-canada" ? "America / Canada" : pill;
    
      const pillLabel = processedPill.toLowerCase() === "e-commerce"
        ? processedPill
        : processedPill.includes('-') 
          ? processedPill.split('-').pop() 
          : processedPill;
    
      return pillLabel.toLowerCase() === displayLabel.toLowerCase();
    });
    setSelectedPills((prevSelectedPills) => prevSelectedPills.filter(pill => pill !== fullLabel));

    if (fullLabel === "Top Companies") {
      setIsTopCompaniesChecked(false);
    } else if (checkedBatches[fullLabel]) {
      setCheckedBatches((prevChecked) => ({
        ...prevChecked,
        [fullLabel]: false
      }));
    } else if (checkedRegions[fullLabel]) {
      setCheckedRegions((prevChecked) => ({
        ...prevChecked,
        [fullLabel]: false
      }));
    } else if (checkedTags[fullLabel]) {
      setCheckedTags((prevChecked) => ({
        ...prevChecked,
        [fullLabel]: false
      }))
    } else if (checkedIndustries[fullLabel]) {
      setCheckedIndustries((prevChecked) => ({
        ...prevChecked,
        [fullLabel]: false
      }));
    }
    setPage(1);
  };

  useEffect(() => {
    if (page === 1) {
      setCompanies([]);
    }
    if (companies.length >= 20 || companies.length === 0) {
      fetchCompanies(setCompanies, page, sortOption, debouncedSearchQuery, selectedPills, checkedBatches, isTopCompaniesChecked, checkedIndustries, checkedRegions, checkedTags).then(() => {
      });
    }
    setLoading(false);
  }, [page, sortOption, debouncedSearchQuery, selectedPills, checkedBatches, isTopCompaniesChecked, checkedIndustries, checkedRegions, checkedTags]);
   
  const getS3LogoUrl = (tags) => {
    if (!tags || tags.length <= 1) {
      return '';
    }

    const logoFileName = tags
      .slice(1)
      .sort()
      .map(tag => tag.toLowerCase())
      .join('-') + '.png';

    const s3BucketUrl = 'https://ycombinations.s3.amazonaws.com/logos/';
    return `${s3BucketUrl}${logoFileName}`;
  };

  const lastCompanyElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading]);

  const navigate = useNavigate();

  const handleHireMeClick = () => {
    navigate('/hire-me');
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div id="app-content">
      <div id="navbar">
        <img className="logo" src={logo} alt="Logo" style={{ width: '56px', height: '55px' }} onClick={handleLogoClick}/>
        <div id="navbar-links">
          <Link to="/">Combinations</Link>
        </div>
        <button onClick={handleHireMeClick} className="hire-me-button">
          Hire Me
        </button>
        <div className="mobile-nav-dropdown">
      {/* Sliding Panel */}
      <div className={`navbar-panel ${isMenuOpen ? 'open' : ''}`}>
        {/* Logo at the top left corner */}
        <img src={logo} alt="Logo" className="panel-logo" />

        <nav className="panel-links">
          <Link to="/" className="panel-link" onClick={toggleMenuIcon}>Combinations</Link>
          <Link to="/hire-me" className="panel-link" onClick={toggleMenuIcon}>Hire Me</Link>
        </nav>

        {/* Button inside the panel when open */}
        {isMenuOpen && (
          <button
            className="mobile-nav-button inside-panel"
            type="button"
            onClick={toggleMenuIcon}
          >
            {/* "X" Icon (Shown when menu is open) */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              aria-hidden="true"
              className="navbar-button-svg"
              width="1.75em"
              height="1.75em"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        )}
      </div>

      {/* Button outside the panel when closed */}
      {!isMenuOpen && (
        <button
          className="mobile-nav-button outside-panel"
          type="button"
          onClick={toggleMenuIcon}
        >
          {/* Hamburger Icon (Visible by Default) */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            aria-hidden="true"
            className="navbar-button-svg"
            width="1.75em"
            height="1.75em"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      )}
    </div>
      </div>

      <div id="content">
        <Routes>
          <Route path="/hire-me" element={<HireMe />} />
          <Route path="/" element={
            <Main
              sortOption={sortOption}
              handleSortChange={handleSortChange}
              isTopCompaniesChecked={isTopCompaniesChecked}
              handleCheckboxChange={handleCheckboxChange}
              checkedBatches={checkedBatches}
              isIndustryOpen={isIndustryOpen}
              toggleIndustry={toggleIndustry}
              checkedIndustries={checkedIndustries}
              checkedRegions={checkedRegions}
              toggleRegion={toggleRegion}
              isRegionOpen={isRegionOpen}
              isTagsOpen={isTagsOpen}
              toggleTags={toggleTags}
              checkedTags={checkedTags}
              immediateSearchQuery={immediateSearchQuery}
              handleSearchChange={handleSearchChange}
              selectedPills={selectedPills}
              handlePillRemove={handlePillRemove}
              companies={companies}
              lastCompanyElementRef={lastCompanyElementRef}
              getS3LogoUrl={getS3LogoUrl}
              yc_logo={yc_logo}
              CompanyToolTip={CompanyToolTip}
              CompanyRating={CompanyRating}
              BatchSelector={BatchSelector}
              IndustryCheckbox={IndustryCheckbox}
              RegionsCheckbox={RegionsCheckbox}
              TagsCheckbox={TagsCheckbox}
              PlusIconButton={PlusIconButton}
              MinusIconButton={MinusIconButton}
              setSearchQuery={setSearchQuery}
              setImmediateSearchQuery={setImmediateSearchQuery}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              switchToTrending={switchToTrending}
              setPage={setPage}
              setSortBy={setSortBy}
              loading={loading}
            />
          } />
        </Routes>
      </div>
    </div>
  );
}

export default App;